<template>
  <div>
    <div class="account-pages mt-5 mb-5">
      <div class="container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    config() {
      return this.$store.getters['app/config']
    },
  },
  metaInfo () {
    return {
      link: [
        { hid: 'favorite-icon', rel: 'icon', href: this.config && this.config.hasOwnProperty('favicon') ? this.config.favicon ? this.config.favicon :'https://rengine.sfo3.cdn.digitaloceanspaces.com/favicon.png' : '' , type: 'image/x-icon' }
      ]
    }
  }
}
</script>
